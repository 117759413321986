/* ErrorPage.css */
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.h2Error {
    font-size: 28px;
    color: #ff0000;
}

.pError {
    font-size: 18px;
    color: #555;
    margin: 20px 0;
}

.error-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
}

.error-button:hover {
    background-color: #0056b3;
}