.employeeBody {
    background-color: #f7f7f7;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.employeeForm {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    margin: 3%;
    margin-left: 45vh;
    padding: 3%;
    background-color: #fefeff;
    min-width: 145vh;
    box-shadow: #a2a2a2 0px 1px 5px;
}

.formH21 {
    padding-left: 5%;
    font-size: 1.7rem;
    width: 100%;
    color: #1a2f67;
    text-align: center;
}

.formTop {
    display: flex;
    font-size: 1.3rem;
    color: #1a2f67;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 16rem;
    cursor: pointer;
}

.formH22 {
    color: #1a2f67;
    text-align: center;
    font-size: 1.5rem;
}

.employeeDiv {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    margin-left: 30%;
}

.employeeRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.employeeColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.employeeButton {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #92c01e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.employeeButton1 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #9ad413;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
    justify-self: end;
    margin-right: 3.1%;
}

.employeeButton2 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #ffffff;
    color: #1a2f67;
    border: 1px solid #1a2f67;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 20%;
    justify-self: end;
}

.employeeButtons {
    display: flex;
    justify-content: end;
}

.divEmployeePassword {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
    border: 1px;
    background-color: #ffffff;
    box-shadow: #33333346 0px 1px 5px;
    color: #63636394;
    border: 1.5px solid #b0b0b094;
    border-radius: 6px;
    height: 5.7vh;
    width: 93%;
}

.employeeLabel {
    font-size: 1rem;
    font-weight: 500;
    color: #000000;

}

.employeeInput {
    height: 5.5vh;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    padding-left: 2%;
    border: 1px solid #b0b0b094;
    border-radius: 6px;
    box-shadow: #33333346 0px 1px 5px;
    font-size: 1rem;
    color: #a4a4a4;
}

.employeeInputPassword {
    height: 4vh;
    margin-top: 20px;
    margin-left: 2px;
    display: flex;
    padding-left: 3%;
    align-items: center;
    border: #33333346 1px solid;
    margin-bottom: 20px;
    border: 1px;
    width: 90%;
    font-size: 1rem;
}

.iconEmployee {
    cursor: pointer;
}

.error-message {
    background-color: #ffffff;
    color: #ff5555;
    padding: 10px;
    margin: 10px 5px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #ff5555;
    width: 50%;
}

.success-message {
    background-color: #fff;
    color: #4CAF50;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #388E3C;
    width: 50%;
}