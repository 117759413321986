.garantiasDiv {
    width: 70%;
    margin-left: 23%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: flex-end;
    margin-top: 3%;
    border: 2px solid #1a2f67;
    border-radius: 10px;
    padding: 50px;
}

.garantiasBody {
    background-color: #fff;
    padding: 10px;

}