/* ShowNotes Container */
.showNotes {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ShowNotes Content */
.showNotes-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 850px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    margin: 0 auto;
    overflow-y: auto;
    height: 85vh;
    width: 40%;
}

.close-button2 {
    display: flex;
    width: 100%;
    font-size: 1.7rem;
    color: #1a2f67;
    justify-content: end;
    cursor: pointer;
}

.modalH2 {
    display: flex;
    color: #1a2f67;
    text-align: start;
    align-content: center;
    width: 100%;
    padding-bottom: 2px;
    border-bottom: 4px solid #1a2f67;
}

/* Close Button */
.close-button {
    background-color: #ff4444;
    color: #fff;
    width: 20px;
    height: 30px;
    border-radius: 5%;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    cursor: pointer;

}

.close-button:hover {
    background-color: #ff0000;
}

/* Table Styles */
.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
}

.table-row {
    width: 100%;
}

.table-cell {
    display: table-cell;
    padding: 10px;
    border: 0px;

}

.table-key {
    font-weight: bold;
    /* Adjust as needed */
}

.table-value {}

.iconEmitted {
    color: #93C01F;
    width: 100%;
    height: 4vh;
}

.pagination {
    display: flex;
    justify-content: end;

}

.page-button {
    background-color: #1a2f67;

}