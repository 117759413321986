/* Estilos gerais */
.close-button1 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.close-button1:hover {
    color: #f83939;
}

.resultBody {
    display: flex;
    flex-direction: row;
    background-color: #f8f8f8;
    padding: 10px;
}

.resultMain {
    background-color: #f7f7f7;
    width: 80%;
    padding-bottom: 80px;
    margin-left: 24%;
}

.divTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.divTable table th{
    background-color: #fff;
    border: 0px;
    color: #1a2f67;
    text-align: center;
}

.many {
    border-top: 5px #1a2f67 solid;
    border-radius: 10px 10px 10px 10px;
    background-color: #fefeff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    width: 94%;
    flex-direction: row;
    padding-inline: 3vh;
}

.divInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5vh 0;
    transition: 2000ms;
}

.h3Info,
.h3Info1 {
    border-bottom: 2px solid #1a2f67;
    color: #1a2f67;
    transform: scale(1) rotate(0);
    width: 30vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    transition: color 0.5s, opacity 0.5s, transform 0.5s;
}

.h3Info:hover {
    cursor: pointer;
    word-wrap: normal;
    overflow: visible;
    white-space: normal;
}

.textInfo,
.okInfo {
    font-size: large;
    text-align: right;
    display: flex;
    /* Para usar flexbox para alinhar verticalmente */
    align-items: center;
    /* Alinhar verticalmente no centro */
}


.okInfo {
    border-radius: 10px;
    padding: 2px;
    color: #92c01e;
    transform: scale(1) rotate(360deg);
    font-size: large;
    text-align: right;
    transition: color 0.5s, opacity 0.5s, transform 0.5s;
}

.thInfo {
    font-size: larger;
    text-align: center;
    width: 70%;
    height: 5vh;
}

.h2Table {
    text-align: start;
}

.manyTable {
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 40px;
    border-radius: 10px 10px 10px 10px;
    background-color: #fefeff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    width: 94%;
    padding-inline: 3vh;
}

.horizontal-align {
    display: flex;
    justify-content: space-between;
    width: 0%;
    transition: width 0.5s ease-in-out;
}

.horizontal-align.expanded {
    width: 100%;
}

.infoClass {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vh;
}

.infoClass1 {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    align-items: center;
    padding: 1vh 2vh;
}

.infoClass2 {
    background-color: #ffffff;
    width: 100%;
    text-align: center;

}

/* Estilos da tabela */
table {
    margin-top: 2%;
    border-collapse: collapse;
    width: 95%;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tr:hover {
    background-color: #f0f0f0;
}

.centralizado {
    text-align: center;
}

.direita {
    text-align: right;
}

/* Outros estilos */
.container2 {
    border-radius: 20px;
    background-color: 'grey';
    padding: 20px;
    align-self: 'center';
    justify-content: 'center';
}

.row {
    display: flex;
    flex-direction: column;
}

.absolute,
.absolute1 {
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    width: 50vh;
    font-size: larger;
    bottom: 4px;
}

.cheaper {
    color: #92c01e;
    /* Mude a cor para verde ou qualquer outra cor de destaque */
    font-weight: bold;
    /* Faça o texto em negrito para destacar */
}


.absolute {
    color: #fff;
    background-color: #92c01e;
}

.absolute1 {
    background-color: rgb(252, 63, 63);
    width: 7vh;
}

.rentabilidade {
    border-radius: 10px 10px 10px 10px;
    background-color: #fefeff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 94%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline: 3vh;
    padding-bottom: 2%;
}

.pInfo {
    text-align: center;
    font-size: 21px;
    color: black;
}

@media screen and (min--moz-device-pixel-ratio: 1.2),
screen and (-o-min-device-pixel-ratio: 1.2/1),
screen and (-webkit-min-device-pixel-ratio: 1.2),
screen and (min-resolution: 190dpi) {
    .resultMain {}

    .many {
        justify-content: center;
    }

    .infoClass {
        width: 28%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        justify-content: space-between;
        align-items: center;
        padding: 1vh 2vh;
    }

    .infoClass1 {
        width: 28%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        align-items: center;
        padding: 1vh 2vh;
    }

    .infoClass2 {
        background-color: #ffffff;
        width: 100%;
        text-align: center;
    }

    .textInfo {}

    .okInfo {
        font-size: large;
        white-space: nowrap;
        overflow: hidden;
    }
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 189dpi) {
    .resultBody {}

    .okInfo {
        font-size: larger;
        white-space: nowrap;
        overflow: hidden;

    }
}